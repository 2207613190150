/* RegisterFormComponent.js (Component) */

import React from "react";

const RegisterFormComponent = ({
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  message,
  moreDetails,
  isSubmitting,
  isRegistered,
  handleSubmit,
}) => {
  return (
    <div className="register">
      <h2>Register</h2>

      {/* Display the main message and more details if available */}
      {message && (
        <div className="alert alert-info">
          {message}
          {moreDetails && (
            <div className="smaller text-danger">{moreDetails}</div>
          )}
        </div>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault(); // Prevent default form submission
          if (!isSubmitting && !isRegistered) {
            handleSubmit(e); // Directly call handleSubmit without debouncing
          }
        }}
      >
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isRegistered} // Disable email input after registration
          />
        </div>

        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={isRegistered} // Disable password input after registration
          />
        </div>

        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">
            Confirm Password
          </label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            disabled={isRegistered} // Disable confirm password input after registration
          />
        </div>

        <button
          type="submit"
          className={`btn btn-primary ${isSubmitting ? "disabled" : ""}`}
          disabled={isSubmitting || isRegistered} // Disable the button while submitting or after successful registration
        >
          {isSubmitting ? "Sending" : isRegistered ? "Registered" : "Register"}
        </button>
      </form>
    </div>
  );
};

export default RegisterFormComponent;
