/* frontend/components/employer/EmployerLoginFormComponent.js */

import React from "react";

const EmployerLoginForm = ({ email, password, setEmail, setPassword, handleSubmit }) => {
    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="employer_email" className="form-label">
                    Email
                </label>
                <input type="email" className="form-control" id="employer_email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className="mb-3">
                <label htmlFor="employer_password" className="form-label">
                    Password
                </label>
                <input type="password" className="form-control" id="employer_password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </div>
            <button type="submit" className="btn btn-primary">
                Login
            </button>
        </form>
    );
};

export default EmployerLoginForm;
