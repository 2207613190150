// frontend/src/pages/Free_JobListPage.js

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestJobsGet } from "../../utils/api";
import JobListComponent from "../../components/user/JobListComponent";
import errorMessages from "../../utils/errorMessages"; // Import error messages

import MainWrapper from "../../components/MainWrapperComponent";

function FreeJobListPage() {
    const [jobs, setJobs] = useState([]); // Jobs state
    const [error, setError] = useState(null); // Error state
    const [loading, setLoading] = useState(true); // Loading state
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch Jobs without requiring a token
        const fetchJobs = async () => {
            try {
                const response = await requestJobsGet("jobs", {}); // Fetch jobs from API
                setJobs(response.data); // Set jobs in state
            } catch (error) {
                console.error(errorMessages.en.jobsFetchFailed, error); // Log error
                setError(errorMessages.en.jobsFetchFailed); // Set error in state
            } finally {
                setLoading(false); // Stop loading once the request completes
            }
        };

        fetchJobs(); // Call the function to fetch jobs on component mount
    }, [navigate]);

    return (
        <MainWrapper>
            <JobListComponent jobs={jobs} error={error} loading={loading} />
        </MainWrapper>
    );
}

export default FreeJobListPage;
