/* frontend/src/components/EmailConfirmation.js */

import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { requestAuthPost } from "../../utils/api"; // Assuming you're using this utility function
import errorMessages from "../../utils/errorMessages"; // Import error messages
import PageWrapper from "../../components/PageWrapperComponent";
import EmailConfirmationComponent from "../../components/user/EmailConfirmationComponent"; // Import the new component

function EmailConfirmation() {
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState("");
    const navigate = useNavigate(); // For automatic redirection

    useEffect(() => {
        const confirmEmail = async () => {
            const token = searchParams.get("token");

            if (!token) {
                setMessage(errorMessages.en.invalidToken);
                return;
            }

            try {
                const response = await requestAuthPost("confirm-email", { token });
                console.log(response);
                setMessage(errorMessages.en.emailConfirmed);

                // Redirect to login page after 4 seconds
                setTimeout(() => {
                    navigate("/login");
                }, 4000);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    setMessage(error.response.data.message);
                } else {
                    setMessage(errorMessages.en.emailConfirmationFailed);
                }

                // Redirect to login page after 3 seconds in case of failure
                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            }
        };

        confirmEmail();
    }, [searchParams, navigate]);

    return (
        <PageWrapper>
            <EmailConfirmationComponent message={message} />
        </PageWrapper>
    );
}

export default EmailConfirmation;
