// frontend/src/components/JobDetailsComponent.js

import React from "react";

const JobDetailsComponent = ({ job, error, message, navigate }) => {
  if (error) {
    return (
      <div className="container">
        <div className="alert alert-danger" role="alert">
          {error} {message}
        </div>
      </div>
    );
  }

  if (!job) {
    return <div>No job details available.</div>;
  }

  const { job_title, job_company_logo, job_description } = job.job_detail;

  return (
    <div className="container p-5">
      <div className="card mt-4">
        <div className="card-body">
          <h1 className="card-title">{job_title}</h1>
          {job_company_logo && (
            <img
              src={job_company_logo}
              alt="Company Logo"
              className="img-fluid mb-4"
              style={{ maxWidth: "200px" }}
            />
          )}
          <p className="card-text p-4">{job_description}</p>
          <button onClick={() => navigate(-1)} className="btn btn-primary">
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsComponent;
