// frontend/src/pages/MiddleLogin.js

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MiddleLoginComponent from "../../components/user/MiddleLoginComponent";
import { requestAuthGet } from "../../utils/api";
import PageWrapper from "../../components/PageWrapperComponent";

function MiddleLogin({ setIsAuthenticated }) {
    const navigate = useNavigate();
    const location = useLocation(); // Use location to check if it's logoff or login
    const [message, setMessage] = useState("Checking login status...");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem("token");

        // Logoff case
        if (location.pathname === "/middle-logoff") {
            setMessage("You have been logged off. Redirecting to the homepage...");
            localStorage.removeItem("token");
            setIsAuthenticated(false); // Explicitly set isAuthenticated to false
            setLoading(false);
            const timeoutId = setTimeout(() => {
                navigate("/");
            }, 1000);
            return () => clearTimeout(timeoutId);
        }

        // Check if no token is present
        if (!token) {
            setMessage("Session expired. Redirecting to login...");
            setIsAuthenticated(false); // Explicitly set isAuthenticated to false
            setLoading(false);
            const timeoutId = setTimeout(() => {
                navigate("/login");
            }, 1000);
            return () => clearTimeout(timeoutId);
        }

        // Verify the token if it exists
        const verifyToken = async () => {
            try {
                const response = await requestAuthGet("verify-token", {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.status === 200) {
                    setMessage("Login Successful! Redirecting to dashboard...");
                    setIsAuthenticated(true); // Set isAuthenticated to true on successful token verification
                    const timeoutId = setTimeout(() => {
                        navigate("/dashboard");
                    }, 1000);
                    return () => clearTimeout(timeoutId);
                }
            } catch (error) {
                setMessage("Your session has expired. Redirecting to login...");
                setIsAuthenticated(false); // Explicitly set isAuthenticated to false on failure
                setLoading(false);
                const timeoutId = setTimeout(() => {
                    navigate("/login");
                }, 1000);
                return () => clearTimeout(timeoutId);
            }
        };

        verifyToken();
    }, [location.pathname, navigate, setIsAuthenticated]);

    return (
        <PageWrapper>
            <MiddleLoginComponent message={message} loading={loading} />
        </PageWrapper>
    );
}

export default MiddleLogin;
