// frontend/src/components/MiddleLoginComponent.js

import React from "react";

const MiddleLoginComponent = ({ message, loading }) => {
  return (
    <div>
      <h2>{message}</h2>
      {loading && <p>Loading...</p>}
    </div>
  );
};

export default MiddleLoginComponent;
