// frontend/components/ProfileComponent.js

import React from "react";

const ProfileComponent = ({ email, userId, avatarUrl, name, message, loading, setAvatar, handleAvatarUpload, navigate }) => {
    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container_ p-5_">
            <h2 className="mb-4">Profile</h2>

            {message && <div className="alert alert-info">{message}</div>}

            <div className="mb-4">
                <strong>Name: </strong>
                {name}
            </div>

            <div className="mb-4">
                <strong>Email: </strong>
                {email}
            </div>

            <div className="mb-4">
                <strong>User ID: </strong>
                {userId}
            </div>

            <div className="mb-4">{avatarUrl ? <img src={avatarUrl} alt="User Avatar" className="img-thumbnail" style={{ width: "150px", height: "150px" }} /> : <p>No avatar available</p>}</div>

            <form onSubmit={handleAvatarUpload}>
                <div className="mb-3">
                    <label htmlFor="avatar" className="form-label">
                        Upload new avatar
                    </label>
                    <input type="file" className="form-control" id="avatar" accept="image/*" onChange={(e) => setAvatar(e.target.files[0])} />
                </div>

                <button type="submit" className="btn btn-primary">
                    Upload Avatar
                </button>
                <button onClick={() => navigate("/dashboard")} className="btn btn-info mt-3">
                    Dashboard
                </button>
            </form>
        </div>
    );
};

export default ProfileComponent;
