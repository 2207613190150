// frontend/src/components/NavBarComponent.js

import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom"; // Import useLocation to get the current path

const NavBarComponent = ({ isAuthenticated, isEmployer, handleLogoff }) => {
  const location = useLocation(); // Get the current route path

  return (
    <Navbar bg="secondary" expand="lg">
      <Navbar.Brand href="/">Job Portal</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {/* Home link */}
          <Nav.Link href="/">Home</Nav.Link>

          {/* Conditionally render Register link */}
          {!isAuthenticated && <Nav.Link href="/register">Register</Nav.Link>}

          {/* Conditionally render Login link unless on the login page */}
          {!isAuthenticated && location.pathname !== "/login" && <Nav.Link href="/login">Login</Nav.Link>}

          {/* Dashboard/Employer Dashboard link based on login status */}
          {isAuthenticated && !isEmployer && <Nav.Link href="/dashboard">Dashboard</Nav.Link>}
          {isAuthenticated && isEmployer && <Nav.Link href="/dashboard-employer">Employer Dashboard</Nav.Link>}

          {/* Show the Logoff button if logged in */}
          {isAuthenticated && <Nav.Link onClick={handleLogoff}>Logoff</Nav.Link>}

          {/* About link */}
          <Nav.Link href="/about">About</Nav.Link>

          {/* Companies Dropdown Menu */}
          <NavDropdown title="Companies" id="basic-nav-dropdown">
            <NavDropdown.Item href="/register-employer">Register Employer</NavDropdown.Item>
            <NavDropdown.Item href="/login-employer">Employer Login</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/help">Help</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBarComponent;
