// frontend/src/pages/ResetPasswordRequest.js

import React, { useState } from "react";
import { requestAuthPost } from "../../utils/api";
import ResetPasswordRequestComponent from "../../components/user/ResetPasswordRequestComponent"; // Import the component
import FormWrapper from "../../components/FormWrapperComponent";

function ResetPasswordRequest() {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await requestAuthPost("reset-password-request", {
                email,
            });

            setMessage(response.data.message);
        } catch (error) {
            setMessage("Failed to send reset email. Please try again.");
        }
    };

    return (
        <FormWrapper>
            <ResetPasswordRequestComponent email={email} setEmail={setEmail} message={message} handleSubmit={handleSubmit} />
        </FormWrapper>
    );
}

export default ResetPasswordRequest;
