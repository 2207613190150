// frontend/src/pages/AboutPage.js

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PageWrapper from "../components/PageWrapperComponent";
import AboutComponent from "../components/AboutComponent";

function AboutPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [message, setMessage] = useState(null);

    // Navigate without loading
    const handleNavigate = (destination) => {
        try {
            navigate(destination || "/about");
        } catch (error) {
            setMessage("Navigation failed. Please try again.");
        }
    };

    return (
        <PageWrapper>
            <AboutComponent handleNavigate={handleNavigate} message={message} />
        </PageWrapper>
    );
}

export default AboutPage;
