// frontend/src/components/NotFoundComponent.js

import React from "react";

const NotFoundComponent = ({ navigate }) => {
  return (
    <div className="not-found">
      <h1>404 - Page Not Found</h1>
      <p>
        The page you are looking for does not exist. You will be redirected to
        the homepage in 10 seconds...
      </p>
      <button onClick={() => navigate("/")}>Go Back to Homepage</button>
    </div>
  );
};

export default NotFoundComponent;
