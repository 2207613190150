/* frontend/pages/user/DashboardPage.js */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { requestAuthGet, requestAuthPost } from "../../utils/api";
import PageWrapper from "../../components/PageWrapperComponent"; // Import the wrapper component
import DashboardComponent from "../../components/user/DashboardComponent"; // Import the presentational component

function Dashboard() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [avatar, setAvatar] = useState(null); // State for the avatar file
  const [avatarUrl, setAvatarUrl] = useState(""); // State for the uploaded avatar URL
  const [email, setEmail] = useState(""); // State for user's email
  const [userId, setUserId] = useState(""); // State for user's ID
  const [redirecting, setRedirecting] = useState(false); // New state to handle redirecting

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    const employerToken = localStorage.getItem("employerToken");

    // If no user token is found, redirect to login or employer dashboard
    if (!userToken) {
      if (employerToken) {
        navigate("/dashboard-employer"); // Redirect to employer dashboard if employer token exists
      } else {
        navigate("/login"); // Redirect to login if neither token exists
      }
      return;
    }

    const fetchDashboard = async () => {
      try {
        const response = await requestAuthGet("dashboard", {
          headers: { Authorization: `Bearer ${userToken}` }, // Use userToken for authorization
        });

        // If the backend responds with a redirect flag, redirect to the employer dashboard
        if (response.data.redirect_to_employer) {
          setRedirecting(true);
          navigate("/dashboard-employer");
          return; // Stop further execution
        }

        // Set the avatar, user ID, email, and welcome message
        setAvatarUrl(response.data.avatar ? response.data.avatar : `https://api.dicebear.com/7.x/pixel-art/svg?seed=${response.data.userId}`);
        setUserId(response.data.userId);
        setEmail(response.data.email);
        setMessage(response.data.message);
      } catch (error) {
        if (error.response?.status === 401) {
          // Handle session expiration
          localStorage.removeItem("userToken");
          setMessage("Session expired. Redirecting to login...");
          setTimeout(() => {
            navigate("/middle-logoff");
          }, 1000);
        } else {
          setMessage("Error loading dashboard.");
        }
        localStorage.removeItem("userToken");
      } finally {
        setLoading(false);
      }
    };

    fetchDashboard();
  }, [navigate]);

  const handleAvatarUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("avatar", avatar);

    try {
      const userToken = localStorage.getItem("userToken"); // Ensure the userToken is used here
      const response = await requestAuthPost("upload-avatar", formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      // Update the avatar URL after a successful upload
      setAvatarUrl(response.data.avatarUrl);
      setMessage("Avatar uploaded successfully!");
    } catch (error) {
      setMessage("Failed to upload avatar.");
    }
  };

  // Do not render the dashboard if we are redirecting or loading
  if (redirecting || loading) {
    return <div>Loading...</div>; // Show a loading spinner while redirecting or loading data
  }

  return (
    <PageWrapper>
      <DashboardComponent email={email} userId={userId} avatarUrl={avatarUrl} message={message} loading={loading} setAvatar={setAvatar} handleAvatarUpload={handleAvatarUpload} navigate={navigate} />
    </PageWrapper>
  );
}

export default Dashboard;
