// frontend/src/components/LoadingComponent.js

import React from "react";

const LoadingComponent = () => {
    return (
        <div className="loading-overlay d-flex justify-content-center align-items-center top-0 start-0 vw-100 vh-100">
            <div className="spinner-border" role="status">
                <span className="sr-only"></span>
            </div>
        </div>
    );
};

export default LoadingComponent;
