/* components/DashboardComponent.js */

import React from "react";

const DashboardComponent = ({ email, userId, avatarUrl, message, loading, setAvatar, handleAvatarUpload, navigate }) => {
    return (
        <div className="dashboard">
            <h2>Dashboard</h2>
            {loading ? <p>Loading...</p> : <p>{message}</p>}
            <p>
                <strong>Email:</strong> {email}
            </p>
            <p className="smaller">
                <i>UserId:</i> {userId}
            </p>
            <div>
                <img src={avatarUrl || `https://api.dicebear.com/7.x/pixel-art/svg?seed=unknown`} alt="User Avatar" className="img-thumbnail" style={{ width: "100px", height: "100px" }} />
            </div>
            <form onSubmit={handleAvatarUpload}>
                <input type="file" accept="image/*" onChange={(e) => setAvatar(e.target.files[0])} />
                <button type="submit">Upload Avatar</button>
            </form>
            <button onClick={() => navigate("/reset-password-request")} className="btn btn-warning mt-3">
                Reset Password
            </button>
            <button onClick={() => navigate("/profile")} className="btn btn-info mt-3">
                Profile
            </button>
        </div>
    );
};

export default DashboardComponent;
