// frontend/pages/ProfilePage.js

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { requestAuthGet, requestAuthPost } from "../../utils/api";
import PageWrapper from "../../components/PageWrapperComponent"; // Import the wrapper component
import ProfileComponent from "../../components/user/ProfileComponent"; // Import the presentational component

function ProfilePage() {
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [avatar, setAvatar] = useState(null); // State for the avatar file
    const [avatarUrl, setAvatarUrl] = useState(""); // State for the uploaded avatar URL
    const [email, setEmail] = useState(""); // State for user's email
    const [userId, setUserId] = useState(""); // State for user's ID
    const [name, setName] = useState(""); // State for user's name

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (!token) {
            navigate("/login");
            return;
        }

        const fetchProfile = async () => {
            try {
                const response = await requestAuthGet("profile", {
                    headers: { Authorization: `Bearer ${token}` },
                });

                setAvatarUrl(response.data.avatar ? response.data.avatar : `https://api.dicebear.com/7.x/pixel-art/svg?seed=${response.data.userId}`);
                setUserId(response.data.userId);
                setEmail(response.data.email);
                setName(response.data.name);
                setMessage(response.data.message);
            } catch (error) {
                if (error.response?.status === 401) {
                    localStorage.removeItem("token");
                    setMessage("Session expired. Redirecting to login...");
                    setTimeout(() => {
                        navigate("/middle-logoff");
                    }, 1000);
                } else {
                    setMessage("Error loading profile.");
                }
                localStorage.removeItem("token");
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [navigate]);

    const handleAvatarUpload = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("avatar", avatar);

        try {
            const token = localStorage.getItem("token");
            const response = await requestAuthPost("upload-avatar", formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            setAvatarUrl(response.data.avatarUrl); // Update avatar URL
            setMessage("Avatar uploaded successfully!");
        } catch (error) {
            setMessage("Failed to upload avatar.");
        }
    };

    return (
        <PageWrapper>
            <ProfileComponent email={email} userId={userId} avatarUrl={avatarUrl} message={message} name={name} loading={loading} setAvatar={setAvatar} handleAvatarUpload={handleAvatarUpload} navigate={navigate} />
        </PageWrapper>
    );
}

export default ProfilePage;
