const errorMessages = {
  en: {
    passwordsDoNotMatch: "Passwords do not match.",
    registrationSuccessful:
      "Registration successful! Please check your email for confirmation.",
    redirectMessage: "You're being redirected to the login page.",
    emailAlreadyExists: "Registration failed. Try a new email address.",
    registrationFailed: "Registration failed. Please try again.",
    unexpectedError: "An unexpected error occurred.",
    invalidToken: "Invalid token.",
    emailConfirmed:
      "Email confirmed successfully! You can now <span class='text-black'><a href='/login'>log in.</a></span>",
    emailConfirmationFailed: "Failed to confirm email. Please try again.",
    jobsFetchFailed: "Error fetching jobs. Please try again later.",
    noJobsAvailable: "No jobs available at the moment.",
    tokenInvalid: "Token is invalid or expired.",
    sessionExpired: "Invalid or expired session. Please log in again.",
    jobDetailsFetchFailed:
      "Failed to fetch job details. Please try again later.",
    noJobDetailsAvailable: "No job details available.",
  },

  pt: {
    passwordsDoNotMatch: "As senhas não coincidem.",
    registrationSuccessful:
      "Registo bem-sucedido! Verifique o seu e-mail para confirmação.",
    redirectMessage: "Redirecionando para a página de login.",
    emailAlreadyExists: "Falha no registo. Tente um novo endereço de e-mail.",
    registrationFailed: "Falha no registo. Por favor, tente novamente.",
    unexpectedError: "Ocorreu um erro inesperado.",
    invalidToken: "Token inválido.",
    emailConfirmed:
      "Email confirmado com sucesso! Pode agora <span class='text-black'><a href='/login'>fazer login.</a></span>",
    emailConfirmationFailed:
      "Falha ao confirmar o e-mail. Por favor, tente novamente.",
    jobsFetchFailed:
      "Erro ao obter as vagas disponíveis. Por favor, tente novamente mais tarde.",
    noJobsAvailable: "Não há vagas disponíveis a apresentar de momento.",
    tokenInvalid: "Token inválido ou expirado.",
    sessionExpired:
      "Sessão inválida ou expirada. Por favor, faça login novamente.",
    jobDetailsFetchFailed:
      "Erro ao obter os detalhes da vaga. Por favor, tente novamente mais tarde.",
    noJobDetailsAvailable: "Nenhum detalhe da vaga disponível.",
  },
};

export default errorMessages;
