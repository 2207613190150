// frontend/src/pages/HomePage.js
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PageWrapper from "../components/PageWrapperComponent";
import HomeComponent from "../components/user/HomeComponent";

function HomePage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [message, setMessage] = useState(null);

    // Navigate without loading
    const handleNavigate = (destination) => {
        try {
            navigate(destination || "/");
        } catch (error) {
            setMessage("Navigation failed. Please try again.");
        }
    };

    return (
        <PageWrapper>
            <HomeComponent handleNavigate={handleNavigate} message={message} />
        </PageWrapper>
    );
}

export default HomePage;
