// frontend/pages/employer/EmployerLoginPage.js

import React, { useState } from "react";
import { requestAuthPost } from "../../utils/api"; // Re-using the existing API utility
import PageWrapper from "../../components/PageWrapperComponent";
import EmployerLoginForm from "../../components/employer/EmployerLoginFormComponent";

function EmployerLogin({ setIsAuthenticated }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear the userToken if the user is logged in as a regular user
    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      localStorage.removeItem("userToken");
    }

    try {
      // Authenticate the employer and set the employerToken
      const response = await requestAuthPost("login-employer", {
        employer_email: email,
        employer_password: password,
      });

      // Store the employer token in localStorage
      localStorage.setItem("employerToken", response.data.token);
      setIsAuthenticated(true, true); // Mark the employer as authenticated
    } catch (error) {
      setMessage(error.response?.data?.message || "Login failed. Please try again.");
    }
  };

  return (
    <PageWrapper>
      <h2>Employer Login</h2>
      {message && <div className="alert alert-danger">{message}</div>}
      <EmployerLoginForm email={email} password={password} setEmail={setEmail} setPassword={setPassword} handleSubmit={handleSubmit} />
    </PageWrapper>
  );
}

export default EmployerLogin;
