// frontend/src/pages/user/LoginPage.js

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { requestAuthPost } from "../../utils/api.js";
import FormWrapper from "../../components/FormWrapperComponent.js";
import LoginForm from "../../components/user/LoginFormComponent"; // Correct the import

function Login({ setIsAuthenticated }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Get the path where the user was trying to go, or default to dashboard
  const from = location.state?.from?.pathname || "/dashboard";

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission default behavior

    // Clear any existing employer token before logging in as a user
    const employerToken = localStorage.getItem("employerToken");
    if (employerToken) {
      localStorage.removeItem("employerToken");
    }

    try {
      const response = await requestAuthPost("login", {
        email,
        password,
      });

      // Store the user token in localStorage
      localStorage.setItem("userToken", response.data.token);

      // Update the authentication state
      setIsAuthenticated(true);

      // Redirect to the original path the user wanted to access or dashboard
      navigate(from);
    } catch (error) {
      // Handle login error and display appropriate message
      setMessage(error.response?.data.message || "Login failed. Please try again.");
    }
  };

  return (
    <FormWrapper>
      <h2>Login</h2>
      {message && <div className="alert alert-danger">{message}</div>}
      <LoginForm email={email} password={password} setEmail={setEmail} setPassword={setPassword} handleSubmit={handleSubmit} />
    </FormWrapper>
  );
}

export default Login;
