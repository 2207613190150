// frontend/src/App.js

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./assets/css/basics.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/custom.css";
import MainWrapper from "./components/MainWrapperComponent";
import NavBarComponent from "./components/NavBarComponentCrossWide";
import HomePage from "./pages/HomePage";
import JobList from "./pages/user/Free_JobListPage";
import JobDetailsPage from "./pages/user/JobDetailsPage";
import Login from "./pages/user/LoginPage";
import MiddleLogin from "./pages/user/MiddleLoginPage";
import Dashboard from "./pages/user/DashboardPage";
import Register from "./pages/user/RegisterPage";
import EmailConfirmation from "./pages/user/EmailConfirmationPage";
import ResetPasswordRequest from "./pages/user/ResetPasswordRequestPage";
import ResetPassword from "./pages/user/SetNewPasswordPage";
import NotFound from "./pages/NotFoundPage";
import ProfilePage from "./pages/user/ProfilePage"; // User Profile Page
import AboutPage from "./pages/AboutPage"; // About Page

// Employer Pages
import EmployerDashboard from "./pages/employer/EmployerDashboardPage";
import EmployerProfile from "./pages/employer/EmployerProfilePage";
import EmployerLogin from "./pages/employer/EmployerLoginPage";
import RegisterEmployer from "./pages/employer/EmployerRegisterPage";
import ConfirmEmailEmployer from "./pages/employer/EmployerConfirmEmailPage"; // Employer Email Confirmation

// ProtectedRoute component to handle protected routes
const ProtectedRoute = ({ element, isAuthenticated, loading }) => {
  const location = useLocation();

  if (loading) return <div>Loading...</div>;

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return element;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEmployer, setIsEmployer] = useState(false);

  // Set authentication and employer status together
  const handleAuthentication = (authStatus, employerStatus = false) => {
    setIsAuthenticated(authStatus);
    setIsEmployer(employerStatus);
  };

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    const employerToken = localStorage.getItem("employerToken");

    if (userToken) {
      setIsAuthenticated(true);
      setIsEmployer(false);
    } else if (employerToken) {
      setIsAuthenticated(true);
      setIsEmployer(true);
    } else {
      setIsAuthenticated(false);
    }

    setLoading(false); // Set loading to false after checking
  }, []);

  // Handle logoff to clear both tokens
  const handleLogoff = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("employerToken");
    setIsAuthenticated(false);
    setIsEmployer(false);
  };

  return (
    <Router>
      <MainWrapper>
        <NavBarComponent isAuthenticated={isAuthenticated} isEmployer={isEmployer} handleLogoff={handleLogoff} />
        <Routes>
          {/* HOME */}
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          {/* USER JOBS */}
          <Route path="/job-list" element={<JobList />} />
          {/* JOB DETAILS - Accessible for both users and employers */}
          <Route path="/jobs/:id" element={<ProtectedRoute element={<JobDetailsPage />} isAuthenticated={isAuthenticated} loading={loading} />} />
          {/* USER REGISTER */}
          <Route path="/register" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Register />} />
          {/* USER LOGIN */}
          <Route path="/login" element={isAuthenticated && !isEmployer ? <Navigate to="/dashboard" /> : <Login setIsAuthenticated={handleAuthentication} />} />
          {/* CONFIRM EMAIL */}
          <Route path="/confirm-email" element={<EmailConfirmation />} />
          {/* USER DASHBOARD */}
          <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} isAuthenticated={isAuthenticated && !isEmployer} loading={loading} />} />
          {/* USER PROFILE */}
          <Route path="/profile" element={<ProtectedRoute element={<ProfilePage />} isAuthenticated={isAuthenticated && !isEmployer} loading={loading} />} />
          {/* USER MIDDLE LOGIN */}
          <Route path="/middle-login" element={<MiddleLogin setIsAuthenticated={handleAuthentication} />} />
          {/* USER LOGOFF */}
          <Route path="/middle-logoff" element={<MiddleLogin setIsAuthenticated={handleAuthentication} />} />
          {/* RESET PASSWORD REQUEST */}
          <Route path="/reset-password-request" element={<ResetPasswordRequest />} />
          {/* RESET PASSWORD */}
          <Route path="/reset-password" element={<ResetPassword />} />
          {/* EMPLOYER ROUTES */}
          <Route path="/dashboard-employer" element={<ProtectedRoute element={<EmployerDashboard />} isAuthenticated={isAuthenticated && isEmployer} loading={loading} />} />
          <Route path="/profile-employer" element={<ProtectedRoute element={<EmployerProfile />} isAuthenticated={isAuthenticated && isEmployer} loading={loading} />} />
          <Route path="/login-employer" element={isAuthenticated && isEmployer ? <Navigate to="/dashboard-employer" /> : <EmployerLogin setIsAuthenticated={handleAuthentication} />} />
          <Route path="/register-employer" element={<RegisterEmployer />} />
          <Route path="/confirm-email-employer" element={<ConfirmEmailEmployer />} />
          {/* 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </MainWrapper>
    </Router>
  );
}

export default App;
