// frontend/src/pages/JobDetailsPage.js

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { requestJobsGet } from "../../utils/api";
import JobDetailsComponent from "../../components/user/JobDetailsComponent";
import PageWrapper from "../../components/PageWrapperComponent";
import LoadingComponent from "../../components/user/LoadingComponent"; // Import the loading component

function JobDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null);
    const [message, setMessage] = useState("");

    useEffect(() => {
        // Get tokens for user and employer
        const userToken = localStorage.getItem("userToken");
        const employerToken = localStorage.getItem("employerToken");

        // If neither token is present, redirect to login page
        if (!userToken && !employerToken) {
            navigate("/login"); // Redirect to login if no token exists
            return;
        }

        // Choose the token to use for authorization (give preference to userToken)
        const token = userToken || employerToken;

        const fetchJobDetails = async () => {
            setLoading(true); // Set loading true before fetching

            try {
                const response = await requestJobsGet(
                    `jobs/${id}`,
                    {},
                    {
                        headers: { Authorization: `Bearer ${token}` }, // Use the correct token for the request
                    }
                );

                setJob(response.data);
                setMessage("Job details fetched successfully.");
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // Handle session expiration
                    localStorage.removeItem("userToken");
                    localStorage.removeItem("employerToken");
                    setMessage("Session expired. Redirecting to login...");
                    setTimeout(() => {
                        navigate("/middle-logoff");
                    }, 1000);
                } else {
                    setMessage("Error loading job details.");
                }
                setError("Failed to load job details.");
            } finally {
                setLoading(false); // Set loading false after fetching
            }
        };

        fetchJobDetails();
    }, [id, navigate]);

    if (loading) {
        return <LoadingComponent />; // Show loading spinner while fetching data
    }

    return (
        <PageWrapper>
            <JobDetailsComponent job={job} error={error} message={message} navigate={navigate} />
        </PageWrapper>
    );
}

export default JobDetails;
