// frontend/src/components/ResetPasswordComponent.js

import React from "react";

const ResetPasswordComponent = ({
  newPassword,
  setNewPassword,
  message,
  handleSubmit,
}) => {
  return (
    <div className="reset-password-container">
      <h2>Reset Password</h2>
      {message && <div className="alert alert-info">{message}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="newPassword" className="form-label">
            New Password
          </label>
          <input
            type="password"
            className="form-control"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Reset Password
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordComponent;
