/* frontend/pages/RegisterEmployerPage.js */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestAuthPost } from "../../utils/api";
import FormWrapper from "../../components/FormWrapperComponent";
import EmployerRegisterFormComponent from "../../components/employer/EmployerRegisterFormComponent";

function RegisterEmployer() {
    const navigate = useNavigate();
    const [employer_email, setEmployerEmail] = useState("");
    const [employer_password, setEmployerPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [employer_name, setEmployerName] = useState("");
    const [employer_address, setEmployerAddress] = useState("");
    const [message, setMessage] = useState(null);
    const [moreDetails, setMoreDetails] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false); // Track button disabled state
    const [isRegistered, setIsRegistered] = useState(false); // Track successful registration

    // Form submission handler
    const handleSubmit = async () => {
        if (employer_password !== confirmPassword) {
            setMessage("Passwords do not match.");
            setIsSubmitting(false);
            return;
        }

        try {
            setIsSubmitting(true); // Disable the button
            const response = await requestAuthPost("register-employer", {
                employer_email,
                employer_password,
                employer_name,
                employer_address,
            });
            console.log(response);
            setMessage("Registration successful!");
            setMoreDetails("Redirecting to login...");
            setIsRegistered(true); // Mark the employer as registered
            setTimeout(() => navigate("/login-employer"), 4000); // Redirect to login page after 4 seconds
        } catch (error) {
            if (error.response && error.response.status === 409) {
                setMessage(error.response.data.moreDetails || "Email already exists.");
                setMoreDetails(error.response.data.message || "Registration failed.");
            } else if (error.response && error.response.data) {
                setMessage(error.response.data.moreDetails || "Registration failed.");
                setMoreDetails(error.response.data.message || "Registration failed.");
            } else {
                setMessage("Unexpected error occurred.");
                setMoreDetails("Please try again.");
            }
        } finally {
            setIsSubmitting(false); // Enable the button after completion
        }
    };

    return (
        <FormWrapper>
            <EmployerRegisterFormComponent employer_email={employer_email} setEmployerEmail={setEmployerEmail} employer_password={employer_password} setEmployerPassword={setEmployerPassword} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} employer_name={employer_name} setEmployerName={setEmployerName} employer_address={employer_address} setEmployerAddress={setEmployerAddress} message={message} moreDetails={moreDetails} isSubmitting={isSubmitting} isRegistered={isRegistered} handleSubmit={handleSubmit} />
        </FormWrapper>
    );
}

export default RegisterEmployer;
