// frontend/src/pages/EmployerProfilePage.js

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { requestAuthGet, requestAuthPost } from "../../utils/api";
import PageWrapper from "../../components/PageWrapperComponent";
import EmployerProfileComponent from "../../components/employer/EmployerProfileComponent";

function EmployerProfile() {
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (!token) {
            navigate("/employer-login");
            return;
        }

        const fetchProfile = async () => {
            try {
                const response = await requestAuthGet("employer-profile", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setProfileData(response.data);
            } catch (error) {
                setMessage("Error loading profile.");
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [navigate]);

    const handleProfileUpdate = async (updatedData) => {
        try {
            const token = localStorage.getItem("token");
            await requestAuthPost("update-employer-profile", updatedData, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage("Profile updated successfully!");
        } catch (error) {
            setMessage("Failed to update profile.");
        }
    };

    return (
        <PageWrapper>
            <EmployerProfileComponent profileData={profileData} loading={loading} message={message} handleProfileUpdate={handleProfileUpdate} />
        </PageWrapper>
    );
}

export default EmployerProfile;
