// frontend/src/pages/ResetPassword.js

import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { requestAuthPost } from "../../utils/api";
import ResetPasswordComponent from "../../components/user/SetNewPasswordComponent"; // Import the component

import FormWrapper from "../../components/FormWrapperComponent";

function ResetPassword() {
    const [newPassword, setNewPassword] = useState("");
    const [message, setMessage] = useState("");
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const token = searchParams.get("token");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await requestAuthPost("reset-password", {
                token,
                newPassword,
            });

            console.log(response);
            setMessage("Password has been reset. Redirecting to login...");
            setTimeout(() => {
                navigate("/login");
            }, 3000); // Redirect to login after 3 seconds
        } catch (error) {
            setMessage("Failed to reset password. Token may be expired.");
        }
    };

    return (
        <FormWrapper>
            <ResetPasswordComponent newPassword={newPassword} setNewPassword={setNewPassword} message={message} handleSubmit={handleSubmit} />
        </FormWrapper>
    );
}

export default ResetPassword;
