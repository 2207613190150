// frontend/src/pages/employer/EmployerConfirmEmailPage.js

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { requestAuthPost } from "../../utils/api";

function EmployerConfirmEmail() {
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    // Get token from the URL query parameters
    const query = new URLSearchParams(location.search);
    const token = query.get("token");

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                const response = await requestAuthPost("confirm-email-employer", { token });
                setMessage("Email confirmed successfully!");
                setTimeout(() => navigate("/login-employer"), 3000); // Redirect after success
            } catch (error) {
                setMessage("Email confirmation failed. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            confirmEmail();
        } else {
            setMessage("Invalid confirmation link.");
            setLoading(false);
        }
    }, [navigate, token]);

    return <div className="container">{loading ? <p>Loading...</p> : <div className="alert alert-info">{message}</div>}</div>;
}

export default EmployerConfirmEmail;
