import React from "react";

const EmployerDashboardComponent = ({ data, loading, message }) => {
    if (loading) return <div>Loading...</div>;

    if (!data) return <div>No dashboard data available.</div>;

    return (
        <div>
            <h2>Employer Dashboard</h2>

            {/* Display welcome message */}
            {message && <div className="alert alert-info">{message}</div>}
            <p>{data.welcome_message}</p>

            {/* Employer Information */}
            <small>
                <i>ID: {data.employerId}</i>
            </small>
            <p>Company email: {data.email}</p>
            <p>Company address: {data.address}</p>

            {/* Confirmation Status */}
            <p>
                Account confirmed? <input type="checkbox" checked={data.confirm_email} disabled />
            </p>
        </div>
    );
};

export default EmployerDashboardComponent;

/*



            employerId: employer._id,
            email: employer.employer_email,
            name: employer.employer_name,
            address: employer.employer_address,
            confirm_email: employer.isConfirmed,
            message: "Welcome" + this.name,


            */
