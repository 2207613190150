// frontend/src/pages/NotFound.js

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../components/PageWrapperComponent";
import NotFoundComponent from "../components/NotFoundComponent"; // Import the component

function NotFound() {
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to homepage after 10 seconds
        const timer = setTimeout(() => {
            navigate("/");
        }, 5000); // 5 seconds

        return () => clearTimeout(timer); // Cleanup timer on component unmount
    }, [navigate]);

    return (
        <PageWrapper>
            <NotFoundComponent navigate={navigate} />
        </PageWrapper>
    ); // Pass navigate as a prop to the component
}

export default NotFound;
