// frontend/src/components/ResetPasswordRequestComponent.js

import React from "react";

const ResetPasswordRequestComponent = ({
  email,
  setEmail,
  message,
  handleSubmit,
}) => {
  return (
    <div className="reset-password-container">
      <h2>Reset Password Request</h2>
      {message && <div className="alert alert-info">{message}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Send Reset Email
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordRequestComponent;
