/* frontend/utils/api.js */

import axios from "axios";

const API_URL_AUTH = process.env.REACT_APP_API_URL_AUTH;
const API_URL_JOBS = process.env.REACT_APP_API_URL_JOBS;

export const requestAuthPost = async (endpoint, data, config = {}) => {
    const response = await axios.post(`${API_URL_AUTH}/${endpoint}`, data, config);
    return response;
};

export const requestAuthGet = async (endpoint, config = {}) => {
    const response = await axios.get(`${API_URL_AUTH}/${endpoint}`, config);
    return response;
};

export const requestJobsGet = async (endpoint, config = {}) => {
    const response = await axios.get(`${API_URL_JOBS}/${endpoint}`, config);
    return response;
};
