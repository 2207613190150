/* frontend/src/components/Register.js */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestAuthPost } from "../../utils/api";
import errorMessages from "../../utils/errorMessages"; // Import error messages
import FormWrapper from "../../components/FormWrapperComponent";
import RegisterFormComponent from "../../components/user/RegisterFormComponent"; // Import the new component

function Register() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState(null); // To show success/error messages
    const [moreDetails, setMoreDetails] = useState(null); // To show more error details
    const [isSubmitting, setIsSubmitting] = useState(false); // Track button disabled state
    const [isRegistered, setIsRegistered] = useState(false); // Track successful registration

    localStorage.removeItem("token"); // Clear the token

    // Form submission handler
    const handleSubmit = async () => {
        // Check if passwords match
        if (password !== confirmPassword) {
            setMessage(errorMessages.en.passwordsDoNotMatch);
            setIsSubmitting(false); // Enable button after validation error
            return;
        }

        try {
            setIsSubmitting(true); // Disable the button
            // Make API request to register the user
            const response = await requestAuthPost("register", {
                email,
                password,
            });
            console.log(response);
            setMessage(errorMessages.en.registrationSuccessful);
            setMoreDetails(errorMessages.en.redirectMessage);
            setIsRegistered(true); // Mark the user as registered
            setTimeout(() => navigate("/login-employer"), 4000); // Redirect to login page after 4 seconds
        } catch (error) {
            // Handle specific 409 error for existing user
            if (error.response && error.response.status === 409) {
                setMessage(error.response.data.moreDetails || errorMessages.en.emailAlreadyExists);
                setMoreDetails(error.response.data.message || errorMessages.en.registrationFailed);
            } else if (error.response && error.response.data) {
                setMessage(error.response.data.moreDetails || errorMessages.en.registrationFailed);
                setMoreDetails(error.response.data.message || errorMessages.en.registrationFailed);
            } else {
                setMessage(errorMessages.en.unexpectedError);
                setMoreDetails(errorMessages.en.registrationFailed);
            }
        } finally {
            setIsSubmitting(false); // Enable the button after completion
        }
    };

    return (
        <FormWrapper>
            <RegisterFormComponent email={email} setEmail={setEmail} password={password} setPassword={setPassword} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} message={message} moreDetails={moreDetails} isSubmitting={isSubmitting} isRegistered={isRegistered} handleSubmit={handleSubmit} />
        </FormWrapper>
    );
}

export default Register;
