// frontend/src/components/JobListComponent.js

import React from "react";
import { Link } from "react-router-dom";
import errorMessages from "../../utils/errorMessages"; // Import error messages

const JobListComponent = ({ jobs, isAuthenticated, error, loading }) => {
    if (loading) {
        return (
            <div className="col-12 position-absolute d-flex justify-content-center flex-wrap flex-column vh-100 align-items-center">
                {/* If there's an error, display it */}
                {error && (
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                )}

                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        ); // Bootstrap spinner
    }

    if (error) {
        return (
            <div className="alert alert-danger" role="alert">
                {error}
            </div>
        );
    }

    if (!jobs.length) {
        return <h1>{errorMessages.en.noJobsAvailable}</h1>;
    }

    return (
        <div className="container">
            <h1 className="my-4">Job Listings</h1>
            <div className="row">
                {jobs.map((job) => (
                    <div className="col-md-4" key={job._id}>
                        <div className="card mb-4">
                            <img src={job.job_detail.job_company_logo} className="card-img-top" alt="Company Logo" />
                            <div className="card-body">
                                <h4 className="card-header">{job.job_detail.job_company_name}</h4>
                                <h5 className="card-title">{job.job_detail.job_title}</h5>
                                <p className="card-text">{job.job_detail.job_description.substring(0, 100)}...</p>
                                <Link to={`/jobs/${job._id}`} className="btn btn-primary">
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Conditionally render the dashboard link */}
            {isAuthenticated && (
                <div className="mt-4">
                    <Link to="/dashboard" className="btn btn-success">
                        Go to Dashboard
                    </Link>
                </div>
            )}
        </div>
    );
};

export default JobListComponent;
