// frontend/src/components/HomeComponent.js

import React from "react";

const HomeComponent = ({ handleNavigate, message }) => {
    return (
        <div className="text-center">
            <h1>Welcome to Job Portal</h1>
            <p className="lead">Find your dream job or manage your applications easily!</p>

            {/* Display message if exists */}
            {message && <div className="alert alert-danger">{message}</div>}

            {/* Buttons to navigate */}
            <button
                className="btn btn-primary m-2"
                onClick={() => handleNavigate("/job-list")} // Correctly call handleNavigate
            >
                View Jobs
            </button>

            <button
                className="btn btn-secondary m-2"
                onClick={() => handleNavigate("/login")} // Correctly call handleNavigate
            >
                Login Users
            </button>

            <button
                className="btn btn-secondary m-2"
                onClick={() => handleNavigate("/login-employer")} // Correctly call handleNavigate
            >
                Login Employers
            </button>
        </div>
    );
};

export default HomeComponent;
