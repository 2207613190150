/* EmailConfirmationComponent.js (Component) */

import React from "react";

const EmailConfirmationComponent = ({ message }) => {
  return (
    <div className="email-confirmation">
      <h2>Email Confirmation</h2>
      <div
        className="alert alert-info"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </div>
  );
};

export default EmailConfirmationComponent;
