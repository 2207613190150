import React from "react";

const EmployerProfileComponent = ({ profileData, loading, message, handleProfileUpdate }) => {
    if (loading) return <div>Loading...</div>;

    if (!profileData) return <div>No profile data available.</div>;

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedData = {
            companyName: e.target.companyName.value,
            email: e.target.email.value,
            address: e.target.address.value,
        };
        handleProfileUpdate(updatedData);
    };

    return (
        <div>
            <h2>Employer Profile</h2>
            {message && <div className="alert alert-info">{message}</div>}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="companyName" className="form-label">
                        Company Name
                    </label>
                    <input type="text" className="form-control" id="companyName" defaultValue={profileData.companyName} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input type="email" className="form-control" id="email" defaultValue={profileData.email} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="address" className="form-label">
                        Address
                    </label>
                    <input type="text" className="form-control" id="address" defaultValue={profileData.address} required />
                </div>
                <button type="submit" className="btn btn-primary">
                    Update Profile
                </button>
            </form>
        </div>
    );
};

export default EmployerProfileComponent;
