// frontend/src/pages/employer/EmployerDashboardPage.js

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { requestAuthGet } from "../../utils/api";
import PageWrapper from "../../components/PageWrapperComponent";
import EmployerDashboardComponent from "../../components/employer/EmployerDashboardComponent";

function EmployerDashboard() {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [employerData, setEmployerData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const employerToken = localStorage.getItem("employerToken");
    const userToken = localStorage.getItem("userToken");

    if (!employerToken) {
      if (userToken) {
        navigate("/dashboard");
      } else {
        navigate("/dashboard-employer");
      }
      return;
    }

    const fetchDashboardData = async () => {
      try {
        const response = await requestAuthGet("dashboard-employer", {
          headers: { Authorization: `Bearer ${employerToken}` },
        });

        setEmployerData(response.data);
        setMessage(response.data.message);
      } catch (error) {
        console.error("Error fetching dashboard data:", error.response);
        setMessage(error.response?.data?.message || "Error loading dashboard.");
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <PageWrapper>{employerData && <EmployerDashboardComponent data={employerData} message={message} />}</PageWrapper>;
}

export default EmployerDashboard;
