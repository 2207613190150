// frontend/src/components/LoginFormComponent.js

import React from "react";

const LoginFormComponent = ({ email, password, setEmail, setPassword, handleSubmit, message }) => {
  return (
    <form onSubmit={handleSubmit}>
      {/* Display the message, if any */}
      {message && <div className="alert alert-danger">{message}</div>}

      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
      </div>

      <div className="mb-3">
        <label htmlFor="password" className="form-label">
          Password
        </label>
        <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
      </div>

      <button type="submit" className="btn btn-primary">
        Login
      </button>
    </form>
  );
};

export default LoginFormComponent;
