import React from "react";

const AboutComponent = ({ message, handleNavigate }) => {
    return (
        <div className="text-center">
            <h1>About Our Job Portal</h1>
            <p className="lead">Our platform connects talented professionals with top employers. Whether you're seeking your dream job or looking to hire the best talent, we make it easy!</p>

            {/* Display message if exists */}
            {message && <div className="alert alert-info">{message}</div>}

            {/* Buttons to navigate */}
            <button className="btn btn-primary m-2" onClick={() => handleNavigate("/job-list")}>
                Explore Job Listings
            </button>

            <button className="btn btn-secondary m-2" onClick={() => handleNavigate("/login")}>
                User Login
            </button>

            <button className="btn btn-secondary m-2" onClick={() => handleNavigate("/employer-access")}>
                Employer Access
            </button>
        </div>
    );
};

export default AboutComponent;
