/* frontend/components/employer/EmployerRegisterFormComponent.js */

import React from "react";

const EmployerRegisterFormComponent = ({ employer_email, setEmployerEmail, employer_password, setEmployerPassword, confirmPassword, setConfirmPassword, employer_name, setEmployerName, employer_address, setEmployerAddress, message, moreDetails, isSubmitting, isRegistered, handleSubmit }) => {
    return (
        <div className="register">
            <h2>Register Employer</h2>

            {/* Display the main message and more details if available */}
            {message && (
                <div className="alert alert-info">
                    {message}
                    {moreDetails && <div className="smaller text-danger">{moreDetails}</div>}
                </div>
            )}

            <form
                onSubmit={(e) => {
                    e.preventDefault(); // Prevent default form submission
                    if (!isSubmitting && !isRegistered) {
                        handleSubmit(e); // Directly call handleSubmit
                    }
                }}
            >
                <div className="mb-3">
                    <label htmlFor="employer_email" className="form-label">
                        Employer Email
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="employer_email"
                        value={employer_email}
                        onChange={(e) => setEmployerEmail(e.target.value)}
                        required
                        disabled={isRegistered} // Disable email input after registration
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="employer_password" className="form-label">
                        Password
                    </label>
                    <input
                        type="password"
                        className="form-control"
                        id="employer_password"
                        value={employer_password}
                        onChange={(e) => setEmployerPassword(e.target.value)}
                        required
                        disabled={isRegistered} // Disable password input after registration
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">
                        Confirm Password
                    </label>
                    <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        disabled={isRegistered} // Disable confirm password input after registration
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="employer_name" className="form-label">
                        Employer Name
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="employer_name"
                        value={employer_name}
                        onChange={(e) => setEmployerName(e.target.value)}
                        required
                        disabled={isRegistered} // Disable employer name input after registration
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="employer_address" className="form-label">
                        Employer Address
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="employer_address"
                        value={employer_address}
                        onChange={(e) => setEmployerAddress(e.target.value)}
                        required
                        disabled={isRegistered} // Disable employer address input after registration
                    />
                </div>

                <button
                    type="submit"
                    className={`btn btn-primary ${isSubmitting ? "disabled" : ""}`}
                    disabled={isSubmitting || isRegistered} // Disable the button while submitting or after successful registration
                >
                    {isSubmitting ? "Sending" : isRegistered ? "Registered" : "Register"}
                </button>
            </form>
        </div>
    );
};

export default EmployerRegisterFormComponent;
